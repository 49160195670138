import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { Routes } from '@angular/router';
import { LocalizeRouterModule } from './localize-router.module';
import { LocalizeRouterConfig } from '@owain/ngx-transloco-router/localize-router.interface';

/**
 * Guard to make sure we have single initialization of forRoot
 */
export const LOCALIZE_ROUTER_FORROOT_GUARD = new InjectionToken<LocalizeRouterModule>('LOCALIZE_ROUTER_FORROOT_GUARD');

/**
 * Static provider for keeping track of routes
 */
export const RAW_ROUTES: InjectionToken<Routes[]> = new InjectionToken<Routes[]>('RAW_ROUTES');

/**
 * Type for Caching of default language
 */

// export type CacheMechanism = 'LocalStorage' | 'Cookie';

/**
 * Namespace for fail proof access of CacheMechanism
 */
export enum CacheMechanism {
  LocalStorage = 'LocalStorage',
  SessionStorage = 'SessionStorage',
  Cookie = 'Cookie',
}

/**
 * Boolean to indicate whether to use cached language value
 */
export const USE_CACHED_LANG = new InjectionToken<boolean>('USE_CACHED_LANG');
/**
 * Boolean to indicate whether to translate routes
 */
export const TRANSLATE_ROUTE = new InjectionToken<boolean>('TRANSLATE_ROUTE');
/**
 * Cache mechanism type
 */
export const CACHE_MECHANISM = new InjectionToken<CacheMechanism>('CACHE_MECHANISM');
/**
 * Cache name
 */
export const CACHE_NAME = new InjectionToken<string>('CACHE_NAME');
/**
 * Cookie cache format
 */
export const COOKIE_FORMAT = new InjectionToken<boolean>('COOKIE_FORMAT');
/**
 * Cookie cache format
 */
export const INITIAL_NAVIGATION = new InjectionToken<boolean>('INITIAL_NAVIGATION');

/**
 * Type for default language function
 * Used to override basic behaviour
 */
export type DefaultLanguageFunction = (
  languages: string[],
  cachedLang?: string | null | undefined,
  browserLang?: string | null
) => string;

/**
 * Function for calculating default language
 */
export const DEFAULT_LANG_FUNCTION = new InjectionToken<DefaultLanguageFunction>('DEFAULT_LANG_FUNCTION');

/**
 * Boolean to indicate whether prefix should be set for single language scenarios
 */
export const ALWAYS_SET_PREFIX = new InjectionToken<boolean>('ALWAYS_SET_PREFIX');

const LOCALIZE_CACHE_NAME = 'LOCALIZE_DEFAULT_LANGUAGE';
const DEFAULT_COOKIE_FORMAT = '{{value}};{{expires}}';
const DEFAULT_INITIAL_NAVIGATION = false;

@Injectable()
export class LocalizeRouterSettings implements LocalizeRouterConfig {
  public useCachedLang = inject<boolean>(USE_CACHED_LANG);
  public translateRoute = inject<boolean>(TRANSLATE_ROUTE);
  public alwaysSetPrefix = inject<boolean>(ALWAYS_SET_PREFIX);
  public cacheName = inject<string>(CACHE_NAME);
  public cookieFormat = inject<string>(COOKIE_FORMAT);
  public initialNavigation = inject<boolean>(INITIAL_NAVIGATION);
  public cacheMechanism = inject(CACHE_MECHANISM);
  public defaultLangFunction = inject(DEFAULT_LANG_FUNCTION);
}
